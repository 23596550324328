import {
  addCartDeliveryNote,
  createDeliveryNote,
  getCart,
  getDeliveryNotes,
  removeCartDeliveryNote,
} from 'utils/apollo';
import { addNote as addNoteActions, deliveryNote as deliveryNoteActions } from './actions';
import { all, call, fork, put, select, take, takeEvery } from 'redux-saga/effects';
import { cart as cartActions, updateCart, updateDeliveryNote } from 'containers/MainLayout/actions';

import ActionTypes from './constants';
import MainActionTypes from 'containers/MainLayout/constants';
import { getStore } from 'containers/MainLayout/saga';
import moment from 'moment';
import { selectCheckout } from 'containers/CheckoutPage/selectors';
import { setCheckout } from 'containers/CheckoutPage/actions';

function* updateDeliveryDetails(action) {
  const { payload } = action;
  const checkout = yield select(selectCheckout());
  const data = { ...payload };
  if (payload.date) {
    checkout.deliveryDate[payload.supplierId] = moment(payload.date).format('DD/MM/YYYY');
    yield put(setCheckout(checkout));
  } else if (payload.time) {
    delete data.time;
    const times = payload.time.split(' - ');
    data.startTime = times[0];
    data.endTime = times[1].replace('24:00', '23:59');
    checkout.timeSlot[payload.supplierId] = payload.time;
    yield put(setCheckout(checkout));
  }
  yield put(updateCart(data));
}

function* updateDeliveryNoteFlow(action) {
  const { payload } = action;
  yield put(deliveryNoteActions.request());
  const store = yield call(getStore);
  if (!store) {
    return;
  }
  const { deleted } = payload;
  delete payload.deleted;
  payload.storeId = store.id;
  const response = deleted
    ? yield call(removeCartDeliveryNote, {
        input: payload,
      })
    : yield call(addCartDeliveryNote, {
        input: payload,
      });
  if (response.errors) {
    yield put(deliveryNoteActions.failure(response.errors));
  } else {
    yield put(deliveryNoteActions.success(response.cart));
    yield put(updateDeliveryNote(response.cart));
  }
}

function* addNoteFlow(action) {
  const { payload } = action;
  yield put(addNoteActions.request());
  const response = yield call(createDeliveryNote, payload);
  if (!response.errors) {
    const checkout = yield select(selectCheckout());
    yield put(
      setCheckout({
        deliveryNotes: [...checkout.deliveryNotes, payload],
      }),
    );
  }
}

function* initCheckoutStep2() {
  yield put(cartActions.request());
  const store = yield call(getStore);
  if (!store) {
    return;
  }
  const response = yield call(getCart, { storeId: store.id });
  if (response.errors) {
    yield put(cartActions.failure(response.errors));
  } else {
    yield put(cartActions.success(response));
  }

  const checkout = {
    timeSlot: {},
    deliveryDate: {},
    deliveryNotes: [],
  };

  for (const element of response.cartSet) {
    const selectedDeliveryStart = moment(element.deliveryDatetime.start);
    const selectedDeliveryEnd = moment(element.deliveryDatetime.end);
    if (moment().isBefore(selectedDeliveryStart)) {
      const formatTimeSlots = element.deliveryTimeSlots.map((timeSlot) => ({
        start: moment(timeSlot.start).format('HH:mm'),
        end: moment(timeSlot.end).format('HH:mm'),
      }));
      const isDeliveryTimeMatchToTimeSlot = formatTimeSlots.some(
        (timeSlotItem) =>
          timeSlotItem.start === selectedDeliveryStart.format('HH:mm') &&
          timeSlotItem.end === selectedDeliveryEnd.format('HH:mm'),
      );
      if (isDeliveryTimeMatchToTimeSlot) {
        checkout.timeSlot[element.supplier.id] = `${selectedDeliveryStart.format(
          'HH:mm',
        )} - ${selectedDeliveryEnd.format('HH:mm')}`;
      } else {
        const timeSlotIsSameDefaultDeliveryTimeIndex = formatTimeSlots.findIndex(
          (timeSlot) => timeSlot.start === selectedDeliveryStart.format('HH:mm'),
        );
        if (timeSlotIsSameDefaultDeliveryTimeIndex !== -1) {
          checkout.timeSlot[
            element.supplier.id
          ] = `${formatTimeSlots[timeSlotIsSameDefaultDeliveryTimeIndex].start} - ${formatTimeSlots[timeSlotIsSameDefaultDeliveryTimeIndex].end}`;
        } else {
          checkout.timeSlot[element.supplier.id] = `${formatTimeSlots[0].start} - ${formatTimeSlots[0].end}`;
        }
      }
    }
    checkout.deliveryDate[element.supplier.id] = selectedDeliveryStart.format('DD/MM/YYYY');
    // const orderDeadline = moment(element.orderDeadline);
    // if (selectedDeliveryStart.isSame(moment(), 'day') && orderDeadline.valueOf() < moment().valueOf()) {
    //   yield put(
    //     updateDeliveryDetailsAction({
    //       supplierId: element.supplier.id,
    //       date: selectedDeliveryStart.add(1, 'day').format('YYYY-MM-DD'),
    //     }),
    //   );
    // }
  }

  const deliveryNotesResponse = yield call(getDeliveryNotes);
  if (!deliveryNotesResponse.errors) {
    checkout.deliveryNotes = deliveryNotesResponse;
  }

  yield put(setCheckout(checkout));
}

function* updateCartFlow({ payload }) {
  const checkout = {
    timeSlot: {},
    deliveryDate: {},
    deliveryNotes: [],
  };

  for (const element of payload.cartSet) {
    const selectedDeliveryStart = moment(element.deliveryDatetime.start);
    const selectedDeliveryEnd = moment(element.deliveryDatetime.end);
    if (moment().isBefore(selectedDeliveryStart)) {
      checkout.timeSlot[element.supplier.id] = `${selectedDeliveryStart.format('HH:mm')} - ${selectedDeliveryEnd.format(
        'HH:mm',
      )}`;
    }
    checkout.deliveryDate[element.supplier.id] = selectedDeliveryStart.format('DD/MM/YYYY');
    // const orderDeadline = moment(element.orderDeadline);
    // if (selectedDeliveryStart.isSame(moment(), 'day') && orderDeadline.valueOf() < moment().valueOf()) {
    //   yield put(
    //     updateDeliveryDetailsAction({
    //       supplierId: element.supplier.id,
    //       date: selectedDeliveryStart.add(1, 'day').format('YYYY-MM-DD'),
    //     }),
    //   );
    // }
  }

  const deliveryNotesResponse = yield call(getDeliveryNotes);
  if (!deliveryNotesResponse.errors) {
    checkout.deliveryNotes = deliveryNotesResponse;
  }

  yield put(setCheckout(checkout));
}

function* initCheckoutStep2Flow() {
  yield call(initCheckoutStep2);
}

export default function* checkoutStep2PageSaga() {
  yield all(
    [
      [ActionTypes.UPDATE_DELIVERY_NOTE, updateDeliveryNoteFlow],
      [ActionTypes.ADD_NOTE, addNoteFlow],
      [MainActionTypes.UPDATE_CART_SUCCESS, updateCartFlow],
    ].map(([actionName, handler]) =>
      fork(function* () {
        while (true) {
          const action = yield take(actionName as any);
          yield call(handler as any, action as any);
        }
      }),
    ),
  );
  yield takeEvery(ActionTypes.UPDATE_DELIVERY_DETAILS, updateDeliveryDetails);
  yield fork(initCheckoutStep2Flow);
}
