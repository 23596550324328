import { all, call, put, takeEvery } from 'redux-saga/effects';
import { getAvailableDeliveryTimeSlots, getDeliveryDateOffs, getNextDeliveryDate } from 'utils/apollo';
import {
  loadDeliveryDateOff as loadDeliveryDateOffActions,
  loadDeliveryDateTime as loadDeliveryDateTimeActions,
} from './actions';

import ActionTypes from './constants';
import moment from 'moment';

function* loadDeliveryDateTime(action) {
  const {
    payload: { date, supplierId },
  } = action;

  const start = moment(date, 'DD/MM/YYYY');
  const end = moment(date, 'DD/MM/YYYY');

  const response = yield call(getAvailableDeliveryTimeSlots, {
    supplierId: supplierId,
    start: start.format('YYYY-MM-DD'),
    end: end.format('YYYY-MM-DD'),
  });

  yield put(
    loadDeliveryDateTimeActions.success({
      [supplierId]: { ...(response[0] || { date: '', timeSlots: [] }), loading: false },
    }),
  );
}

function* loadDeliveryDateOff(action) {
  const {
    payload: { supplierId },
  } = action;
  const [data, nextDeliveryDate] = yield all([
    call(getDeliveryDateOffs, {
      supplierId: supplierId,
    }),
    call(getNextDeliveryDate, {
      supplierId: supplierId,
    }),
  ]);
  yield put(
    loadDeliveryDateOffActions.success({
      [supplierId]: {
        data: data,
        nextDeliveryDate: nextDeliveryDate,
        loading: false,
      },
    }),
  );
}

// Individual exports for testing
export default function* deliveryDateTimeSaga() {
  // See example in containers/HomePage/saga.js
  yield takeEvery(ActionTypes.LOAD_DELIVERY_DATE_TIME_REQUEST, loadDeliveryDateTime);
  yield takeEvery(ActionTypes.LOAD_DELIVERY_DATE_OFF_REQUEST, loadDeliveryDateOff);
}
