/**
 *
 * DeliveryDateTime
 *
 */

import {
  loadDeliveryDateOff as loadDeliveryDateOffActions,
  loadDeliveryDateTime as loadDeliveryDateTimeActions,
} from './actions';
import moment, { Moment } from 'moment';

import CalendarComponent from 'components/TrackingComponents/Calendar/Calendar';
import { FormattedMessage } from 'react-intl';
import React from 'react';
import Select from 'components/Select/Select';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import injectReducer from 'utils/injectReducer';
import injectSaga from 'utils/injectSaga';
import makeSelectDeliveryDateTime from './selectors';
import media from 'utils/mediaStyle';
import reducer from './reducer';
import saga from './saga';
import styled from 'styled-components';
import utilsMessages from 'utils/messages';
import dayjs from 'dayjs';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import isoWeek from 'dayjs/plugin/isoWeek';
import SVGIcon from '../../components/SVGIcon/SVGIcon';
import InFoIcon from './info-circle.svg';
import { Modal as AModal } from 'antd';
import translations from 'translations';

const Calendar = styled(CalendarComponent)`
  position: relative;
  z-index: ${({ open }) => (open ? '10000' : 'unset')};
`;

const Modal = styled(AModal)`
  .ant-modal-content {
    padding: 20px 16px 12px;
  }
  .ant-modal-title {
    font-size: 24px;
    font-weight: 500;
    color: #595959;
    margin-bottom: 20px !important;
  }
  .ant-modal-footer {
    margin-top: 20px !important;
  }
`;

const Label = styled.div`
  color: rgba(0, 0, 0, 0.54);
  font-size: 14px;
  text-transform: capitalize;
  white-space: nowrap;
  ${media.lg`
    min-width: 112pt;
  `};
`;

const Box = styled.div`
  flex: 1;
  padding-right: 4px;
  padding-left: 4px;
`;

const SameDayDelivery = styled.div`
  margin-top: 4px;
  width: fit-content;
  display: flex;
  align-items: center;
  gap: 4px;
  cursor: pointer;
`;

const SameDayDeliveryText = styled.div`
  font-size: 14px;
  color: #f44336;
`;
const SameDayDeliveryTextContainer = styled.div`
  display: flex;
  gap: 4px;
`;

const SameDayDeliveryInfoText = styled.div``;
const SameDayDeliveryInfoTextBold = styled.span`
  margin-right: 2px;
  font-weight: 600;
`;
interface OwnProps {
  deliveryDateTime: any;
}

interface StateProps {
  supplierId: number;
  deliveryDate: string;
  deliveryTime: string;
  isOpenCalendar?: boolean;
  onDeliveryDateChange: (date) => void;
  onDeliveryTimeChange: (time) => void;
  setOpenCalendar: (status) => void;
}

interface DispatchProps {
  loadDeliveryDateTime: (date: any) => void;
  loadDeliveryDateOff: (date: any) => void;
}

type Props = StateProps & DispatchProps & OwnProps;
dayjs.extend(isSameOrBefore);
dayjs.extend(isoWeek);
class DeliveryDateTime extends React.PureComponent<Props> {
  public state = {
    isOpenSameDayNote: false,
    isOpenConfirmSameDayDelivery: false,
  };
  public componentDidMount() {
    this.props.loadDeliveryDateTime({ date: this.props.deliveryDate, supplierId: this.props.supplierId });
    this.props.loadDeliveryDateOff({ supplierId: this.props.supplierId });
    let previousDayDeliverySelected = window.localStorage.getItem('dayDelivery');
    if (previousDayDeliverySelected && moment(previousDayDeliverySelected, 'DD/MM/YYYY').isBefore(moment(), 'day')) {
      window.localStorage.setItem('dayDelivery', '');
      previousDayDeliverySelected = '';
    }
    if (!previousDayDeliverySelected && moment(this.props.deliveryDate, 'DD/MM/YYYY').isSame(moment(), 'day')) {
      let newDeliveryDay = moment().add(1, 'day').format('DD/MM/YYYY');
      this.props?.deliveryDateTime?.deliveryDateOff[this.props.supplierId]?.data?.some((dayOff) => {
        if (moment(newDeliveryDay, 'DD/MM/YYYY').isSame(moment(dayOff, 'YYYY-MM-DD'), 'day')) {
          newDeliveryDay = moment(newDeliveryDay, 'DD/MM/YYYY').add(1, 'day').format('DD/MM/YYYY');
          return false;
        }
        return true;
      });
      this.props.onDeliveryDateChange(moment(newDeliveryDay, 'DD/MM/YYYY').format('YYYY-MM-DD'));
    }
  }

  public disabledStartDate = (value: dayjs.Dayjs) => {
    const deliveryData = this.props.deliveryDateTime.deliveryDateOff[this.props.supplierId];
    if (!deliveryData) {
      return true;
    }
    const { data, nextDeliveryDate } = deliveryData;
    const item = data.find(
      (item) =>
        (value.isoWeekday() === +item || dayjs(item, 'YYYY-MM-DD').isSame(value, 'day')) &&
        dayjs(nextDeliveryDate, 'YYYY-MM-DD').isSameOrBefore(value),
    );
    if (item || !dayjs(nextDeliveryDate, 'YYYY-MM-DD').isSameOrBefore(value)) {
      return true;
    }
    return false;
  };

  public onCalendarChange = (value: Moment) => {
    this.props.onDeliveryDateChange(value.toISOString());
    // this.props.loadDeliveryDateTime({ date: value.format('DD/MM/YYYY'), supplierId: this.props.supplierId });
  };

  public onSelectChange = (value: string) => {
    this.props.onDeliveryTimeChange(value);
  };

  public handleDisplaySameDayNoteModal = (status: boolean) => {
    this.setState({ ...this.state, isOpenSameDayNote: status });
  };

  public render() {
    const { deliveryDate, deliveryTime } = this.props;
    const { timeSlots, loading } = this.props.deliveryDateTime.deliveryDateTime[this.props.supplierId] || {
      timeSlots: [],
      loading: true,
    };
    const isSameDayDelivery = moment(deliveryDate, 'DD/MM/YYYY').isSame(moment(), 'day');
    return (
      <React.Fragment>
        <Box>
          <Label style={{ marginBottom: 8 }}>
            <FormattedMessage {...utilsMessages.deliveryDate} />:
          </Label>
          <Calendar
            trackingCategory="Checkout Step 2 | DatePicker"
            trackingAction="Select Delivery Date"
            size="middle"
            allowClear={false}
            value={dayjs(deliveryDate, 'DD/MM/YYYY')}
            disabledDate={this.disabledStartDate}
            onChange={(value) => {
              this.onCalendarChange(value);
              window.localStorage.setItem('dayDelivery', moment(value.toISOString()).format('DD/MM/YYYY'));
              this.props.setOpenCalendar(false);
            }}
            onOpenChange={(open) => this.props.setOpenCalendar(open)}
            open={this.props.isOpenCalendar}
            onClick={() => this.props.setOpenCalendar(true)}
          />
          {isSameDayDelivery && (
            <SameDayDelivery onClick={() => this.handleDisplaySameDayNoteModal(true)}>
              <SameDayDeliveryText>
                <FormattedMessage {...utilsMessages.sameDayDelivery} />
              </SameDayDeliveryText>
              <SVGIcon style={{ marginTop: '2px' }} src={InFoIcon} />
            </SameDayDelivery>
          )}
        </Box>
        <Box>
          <Label style={{ marginBottom: 8 }}>
            <FormattedMessage {...utilsMessages.deliveryTime} />:
          </Label>
          <Select
            loading={loading}
            value={deliveryTime}
            onChange={this.onSelectChange}
            size="middle"
            items={timeSlots.map((t) => {
              return {
                label: t.end === '23:59' ? `${t.start} - 24:00` : `${t.start} - ${t.end}`,
                value: `${t.start} - ${t.end}`,
              };
            })}
          />
        </Box>
        <Modal
          open={this.state.isOpenSameDayNote}
          title={translations(utilsMessages.sameDayDeliveryTitle)}
          onOk={() => this.handleDisplaySameDayNoteModal(false)}
          onCancel={() => this.handleDisplaySameDayNoteModal(false)}
          centered
          closable={false}
          okText={translations(utilsMessages.iUnderstand)}
          okButtonProps={{
            size: 'large',
            style: {
              width: '100%',
              borderRadius: '4px',
            },
          }}
          footer={(_, { OkBtn }) => (
            <>
              <OkBtn />
            </>
          )}
        >
          <SameDayDeliveryTextContainer>
            {`\u2022`}
            <SameDayDeliveryInfoText>
              <SameDayDeliveryInfoTextBold>
                <FormattedMessage {...utilsMessages.orderCutOffTime} />:
              </SameDayDeliveryInfoTextBold>
              <FormattedMessage {...utilsMessages.orderCutOffTimeContent} />
            </SameDayDeliveryInfoText>
          </SameDayDeliveryTextContainer>
          <SameDayDeliveryTextContainer>
            {`\u2022`}
            <SameDayDeliveryInfoText>
              <SameDayDeliveryInfoTextBold>
                <FormattedMessage {...utilsMessages.deliveryTimeSameDay} />:
              </SameDayDeliveryInfoTextBold>
              <FormattedMessage {...utilsMessages.deliveryTimeSameDayContent} />
            </SameDayDeliveryInfoText>
          </SameDayDeliveryTextContainer>
          <SameDayDeliveryTextContainer>
            {`\u2022`}
            <SameDayDeliveryInfoText>
              <SameDayDeliveryInfoTextBold>
                <FormattedMessage {...utilsMessages.fullfillment} />:
              </SameDayDeliveryInfoTextBold>
              <FormattedMessage {...utilsMessages.fullfillmentContent} />
            </SameDayDeliveryInfoText>
          </SameDayDeliveryTextContainer>
        </Modal>
      </React.Fragment>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  deliveryDateTime: makeSelectDeliveryDateTime(),
});

function mapDispatchToProps(dispatch) {
  return {
    loadDeliveryDateTime: (data: any) => dispatch(loadDeliveryDateTimeActions.request(data)),
    loadDeliveryDateOff: (data: any) => dispatch(loadDeliveryDateOffActions.request(data)),
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

const withReducer = injectReducer({ key: 'deliveryDateTime', reducer: reducer });
const withSaga = injectSaga({ key: 'deliveryDateTime', saga: saga });

export default compose<React.ComponentType<StateProps>>(withReducer, withSaga, withConnect)(DeliveryDateTime);
