import { ApplicationRootState } from 'types';
import { createSelector } from 'reselect';
import { initialState } from './reducer';

/**
 * Direct selector to the deliveryDateTime state domain
 */

const selectDeliveryDateTimeDomain = (state: ApplicationRootState) => {
  return state ? state.deliveryDateTime : initialState;
};

/**
 * Other specific selectors
 */

/**
 * Default selector used by DeliveryDateTime
 */

const selectDeliveryDateTime = () =>
  createSelector(selectDeliveryDateTimeDomain, substate => {
    return substate;
  });

export default selectDeliveryDateTime;
export { selectDeliveryDateTimeDomain };
