/*
 *
 * DeliveryDateTime reducer
 *
 */

import { ContainerActions, ContainerState } from './types';

import ActionTypes from './constants';

export const initialState: ContainerState = {
  deliveryDateTime: {},
  deliveryDateOff: {},
};

function deliveryDateTimeReducer(state: ContainerState = initialState, action: ContainerActions) {
  const { supplierId } = (action.payload || {}) as any;
  switch (action.type) {
    case ActionTypes.LOAD_DELIVERY_DATE_TIME_REQUEST:
      return {
        ...state,
        deliveryDateTime: {
          ...state.deliveryDateTime,
          [supplierId]: {
            loading: true,
            date: '',
            timeSlots: [],
          },
        },
      };
    case ActionTypes.LOAD_DELIVERY_DATE_TIME_SUCCESS:
      return {
        ...state,
        deliveryDateTime: {
          ...state.deliveryDateTime,
          ...action.payload,
        },
      };
    case ActionTypes.LOAD_DELIVERY_DATE_OFF_REQUEST:
      return {
        ...state,
        deliveryDateOff: {
          ...state.deliveryDateOff,
          [supplierId]: {
            loading: true,
            data: [],
            nextDeliveryDate: null,
          },
        },
      };
    case ActionTypes.LOAD_DELIVERY_DATE_OFF_SUCCESS:
      return {
        ...state,
        deliveryDateOff: {
          ...state.deliveryDateOff,
          ...action.payload,
        },
      };
    default:
      return state;
  }
}

export default deliveryDateTimeReducer;
