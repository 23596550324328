import React, { useState } from 'react';
import Footer, { ButtonWrapper, FooterWrapper } from './Footer';
import StepRow, { StepBox } from './StepRow';
import Value, { SupplierName } from './Value';

import { Button as AButton, Modal as AModal } from 'antd';
import { ErrorBoundary } from 'components/CategoryNav/ErrorBoundary';
import SVGIcon from 'components/SVGIcon/SVGIcon';
import { Viewport } from 'components/ViewportProvider';
import messages from 'containers/CheckoutPage/messages';
import DeliveryDateTime from 'containers/DeliveryDateTime';
import moment from 'moment';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import translations from 'translations';
import utilsMessages from 'utils/messages';
import triggerGA4EcommerceEvent, { EventName } from 'utils/triggerGA4Event';
import Button from '../Button/Button';
import Checkbox from '../Checkbox/Checkbox';
import SubplierMark from '../SubplierMark/SubplierMark';
import Label from './Label';
import ModalNote from './ModalNote';
import StepCol from './StepCol';
import StepContainer from './StepContainer';
import StepItem from './StepItem';
import DangerIcon from './danger.svg';

const ConfirmSameDayDeliveryModal = styled(AModal)`
  .ant-modal-content {
    padding: 24px 16px 12px;
  }
  .ant-modal-title {
    font-size: 24px;
    font-weight: 500;
    color: #595959;
    margin-bottom: 20px !important;
  }
  .ant-modal-footer {
    display: flex;
    justify-content: center;
    button {
      flex-grow: 1;
    }
    margin-top: 20px !important;
  }
`;

const ConfirmModalTitle = styled.div`
  margin: -8px -16px 0;
  padding: 0 16px 16px;
  border-bottom: 1px solid #e0e0e0;
`;
const NextStepButton = styled(AButton)`
  border-radius: 4px !important;
`;
const ConfirmContentModal = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;
export default ({
  cart,
  checkout,
  loading,
  onGoPreviousStep,
  onCreateOrderDraft,
  onChangeDeliveryDate,
  onChangeDeliveryTime,
  onSaveNote,
  onUpdateDeliveryNote,
  setOpenConfirmSameDayDeliveryModal,
  orderDisabled,
  openConfirmSameDayDeliveryModal,
}: {
  checkout: any;
  loading: boolean;
  cart: { cartSet: any[] };
  onGoPreviousStep: () => void;
  onCreateOrderDraft: () => void;
  onChangeDeliveryDate: (data: any) => void;
  onChangeDeliveryTime: (data: any) => void;
  onSaveNote: (data: any) => void;
  onUpdateDeliveryNote: (data: any) => void;
  setOpenConfirmSameDayDeliveryModal: (data: boolean) => void;
  orderDisabled: boolean;
  openConfirmSameDayDeliveryModal: boolean;
}) => {
  const [isOpenNoteModal, onOpenNoteModal] = useState(0);
  const [isOpenCalendar, setOpenCalendar] = useState(false);
  const [reuseNote, onReuseNote] = useState(false);
  const deliveryNotes = checkout.deliveryNotes;

  const onChangeDate = (supplier: any) => (date: any) => {
    onChangeDeliveryDate({
      supplierId: supplier.id,
      date: moment(date).format('YYYY-MM-DD'),
    });
  };

  const onChangeTime = (supplier: any) => (time: any) => {
    onChangeDeliveryTime({
      supplierId: supplier.id,
      time: time,
    });
  };

  const onAddNote = (text: any) => {
    if (reuseNote) {
      onSaveNote({ text: text });
    }
    const item = cart.cartSet[isOpenNoteModal - 1];
    onUpdateDeliveryNote({
      supplierId: item.supplier.id,
      note: text,
    });
    onOpenNoteModal(0);
  };

  const onCheckDeliveryNote = (item: any) => (text: string) => (event: any) => {
    onUpdateDeliveryNote({
      supplierId: item.supplier.id,
      note: text,
      deleted: !event.target.checked,
    });
  };

  const handleRedirectToStep3 = () => {
    let isSameDayDelivery: boolean = false;
    for (const cartItem of cart.cartSet) {
      const deliveryDay = checkout.deliveryDate[cartItem.supplier.id];
      if (moment(deliveryDay, 'DD/MM/YYYY').isSame(moment(), 'day')) {
        isSameDayDelivery = true;
      } else {
        isSameDayDelivery = false;
      }
    }
    if (isSameDayDelivery) {
      setOpenConfirmSameDayDeliveryModal(true);
      return;
    }
    onCreateOrderDraft();
    triggerGA4EcommerceEvent(EventName.ADD_SHIPPING_INFO, {
      ...cart.cartSet[0],
    });
  };
  const notIncludeAlcoholProduct = () => {
    return cart?.cartSet?.every((item) => {
      const isIncludesAlcoholProduct = item?.items?.some((it) => it?.product?.category?.parent?.name === 'Alcohol');
      return !isIncludesAlcoholProduct;
    });
  };
  return (
    <Viewport.Consumer>
      {({ width }) => {
        const isMobileSize = width < 575;

        return (
          <StepContainer>
            {cart.cartSet.map((sup: any, index) => {
              if (sup.deliveryTimeSlots) {
                const specificNotes: string[] = [];
                sup.deliveryNotes.forEach((dn: string) => {
                  const note = deliveryNotes.find((n) => n.text === dn);
                  if (!note) {
                    specificNotes.push(dn);
                  }
                });

                return (
                  <ErrorBoundary key={`step-2-${index}`}>
                    <StepItem>
                      <StepBox>
                        <StepRow>
                          <StepCol span={2}>
                            {!isMobileSize && (
                              <Label>
                                <FormattedMessage {...utilsMessages.supplierName} />
                              </Label>
                            )}
                            <SupplierName>
                              <SubplierMark
                                className={sup.supplier.private ? 'unregistered' : ''}
                                style={{ width: '16px', marginRight: '3pt' }}
                              />{' '}
                              {sup.supplier.name}
                            </SupplierName>
                          </StepCol>
                          <StepCol className="hideOnMobile">
                            <Label>
                              <FormattedMessage {...utilsMessages.totalItem} />
                            </Label>
                            <Value>
                              {sup.items.length}
                              &nbsp;
                              <FormattedMessage {...utilsMessages.items} />
                            </Value>
                          </StepCol>
                          {!isMobileSize && (
                            <>
                              <StepCol>
                                <Label>
                                  <FormattedMessage {...utilsMessages.earliest} />
                                </Label>
                                <Value>{moment(sup.earliestDate).format('DD/MM/YYYY')}</Value>
                              </StepCol>
                              <StepCol>
                                <Label>
                                  <FormattedMessage {...utilsMessages.paymentMethod} />
                                </Label>
                                <Value>
                                  {notIncludeAlcoholProduct() ? (
                                    <FormattedMessage {...utilsMessages[sup.paymentMethod]} />
                                  ) : (
                                    <FormattedMessage {...utilsMessages.BANK_TRANSFER} />
                                  )}
                                </Value>
                              </StepCol>
                            </>
                          )}
                        </StepRow>
                        {isMobileSize && (
                          <StepRow className="justify-content-between">
                            <StepCol className="flex-initial">
                              <Label>
                                <FormattedMessage {...utilsMessages.earliest} />
                              </Label>
                              <Value>{moment(sup.earliestDate).format('DD/MM/YYYY')}</Value>
                            </StepCol>
                            <StepCol className="flex-initial">
                              <Label>
                                <FormattedMessage {...utilsMessages.paymentMethod} />
                              </Label>
                              <Value>
                                {notIncludeAlcoholProduct() ? (
                                  <FormattedMessage {...utilsMessages[sup.paymentMethod]} />
                                ) : (
                                  <FormattedMessage {...utilsMessages.BANK_TRANSFER} />
                                )}
                              </Value>
                            </StepCol>
                          </StepRow>
                        )}
                      </StepBox>
                      <StepBox>
                        <StepRow>
                          <div
                            style={{
                              flex: 2,
                              display: 'flex',
                              marginBottom: '20pt',
                            }}
                          >
                            {!!checkout.deliveryDate[sup.supplier.id] && (
                              <DeliveryDateTime
                                supplierId={sup.supplier.id}
                                deliveryDate={checkout.deliveryDate[sup.supplier.id]}
                                deliveryTime={checkout.timeSlot[sup.supplier.id]}
                                onDeliveryDateChange={onChangeDate(sup.supplier)}
                                onDeliveryTimeChange={onChangeTime(sup.supplier)}
                                isOpenCalendar={isOpenCalendar}
                                setOpenCalendar={setOpenCalendar}
                              />
                            )}
                          </div>
                          <StepCol space>
                            <Label>
                              <FormattedMessage {...utilsMessages.noteForDelivery} />
                            </Label>
                            {deliveryNotes.map((item: any) => (
                              <div key={item.id}>
                                <Checkbox
                                  size="small"
                                  checked={sup.deliveryNotes.indexOf(item.text) > -1}
                                  onChange={onCheckDeliveryNote(sup)(item.text)}
                                >
                                  {item.text}
                                </Checkbox>
                              </div>
                            ))}
                            {specificNotes.map((text: any) => (
                              <div key={text}>
                                <Checkbox size="small" checked={true} onChange={onCheckDeliveryNote(sup)(text)}>
                                  {text}
                                </Checkbox>
                              </div>
                            ))}
                            <Button
                              type="link"
                              size="small"
                              onClick={() => {
                                onOpenNoteModal(index + 1);
                              }}
                            >
                              {'+ '}
                              <FormattedMessage {...utilsMessages.addNote} />
                            </Button>
                          </StepCol>
                        </StepRow>
                      </StepBox>
                    </StepItem>
                  </ErrorBoundary>
                );
              }

              return null;
            })}
            <FooterWrapper>
              <Footer>
                <ButtonWrapper>
                  <Button size="large" onClick={onGoPreviousStep}>
                    <FormattedMessage {...utilsMessages.back} />
                  </Button>
                  <div style={{ width: '6pt' }} />
                  <Button
                    loading={loading}
                    type="primary"
                    onClick={handleRedirectToStep3}
                    disabled={orderDisabled}
                    size="large"
                  >
                    <FormattedMessage {...utilsMessages.next} />
                  </Button>
                </ButtonWrapper>
              </Footer>
            </FooterWrapper>
            <ModalNote
              headerLabel={<FormattedMessage {...utilsMessages.noteForDelivery} />}
              isOpen={!!isOpenNoteModal}
              onSubmit={onAddNote}
              onClose={() => onOpenNoteModal(0)}
              note={''}
            >
              <Checkbox checked={reuseNote} onChange={() => onReuseNote(!reuseNote)}>
                <FormattedMessage {...messages.saveDeliveryNote} />
              </Checkbox>
            </ModalNote>
            <ConfirmSameDayDeliveryModal
              open={openConfirmSameDayDeliveryModal}
              title={
                <ConfirmModalTitle>
                  <FormattedMessage {...utilsMessages.notice} />
                </ConfirmModalTitle>
              }
              centered
              onOk={() => {
                setOpenCalendar(true);
                setOpenConfirmSameDayDeliveryModal(false);
              }}
              onCancel={() => setOpenConfirmSameDayDeliveryModal(false)}
              closable={false}
              okText={translations(utilsMessages.changeDay)}
              okButtonProps={{
                size: 'large',
                style: {
                  borderRadius: '4px',
                },
              }}
              cancelButtonProps={{
                size: 'large',
                style: {
                  borderRadius: '4px',
                },
              }}
              footer={(_, { OkBtn }) => (
                <>
                  <NextStepButton
                    size="large"
                    onClick={() => {
                      setOpenConfirmSameDayDeliveryModal(false);
                      onCreateOrderDraft();
                      triggerGA4EcommerceEvent(EventName.ADD_SHIPPING_INFO, {
                        ...cart.cartSet[0],
                      });
                    }}
                  >
                    <FormattedMessage {...utilsMessages.confirm} />
                  </NextStepButton>
                  <OkBtn />
                </>
              )}
            >
              <ConfirmContentModal>
                <SVGIcon src={DangerIcon} />
                <FormattedMessage {...utilsMessages.confirmSamDayDelivery} />
              </ConfirmContentModal>
            </ConfirmSameDayDeliveryModal>
          </StepContainer>
        );
      }}
    </Viewport.Consumer>
  );
};
