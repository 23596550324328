/**
 *
 * CheckoutStep1Page
 *
 */

import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose, Dispatch } from 'redux';
import { push } from 'connected-react-router';
import { Helmet } from 'react-helmet';
import translations from 'translations';
import messages from 'containers/CheckoutPage/messages';

import injectSaga from 'utils/injectSaga';
import injectReducer from 'utils/injectReducer';
import selectCheckoutStep1Page from './selectors';
import reducer from './reducer';
import saga from './saga';
import { RootState } from './types';
import CheckoutStep2 from 'components/Checkout/CheckoutStep2';
import { selectCart, selectOrderDisabled } from 'containers/MainLayout/selectors';
import { updateDeliveryDetails, addNoteSubmit, updateDeliveryNote } from './actions';
import { createOrderDraft, setOpenConfirmSameDayDeliveryModal } from 'containers/CheckoutPage/actions';
import {
  selectCheckout,
  selectLoadingCheckout,
  selectOpenConfirmSameDayDeliveryModal,
} from 'containers/CheckoutPage/selectors';

interface OwnProps {
  cart: {
    cartSet: any[];
  };
  checkout: any;
  loading: boolean;
  orderDisabled: {
    orderDisabled: boolean;
  };
  openConfirmSameDayDeliveryModal: boolean;
}

interface StateProps {}

interface DispatchProps {
  onGoPreviousStep: () => void;
  onCreateOrderDraft: () => void;
  onUpdateDeliveryDetails: (data: any) => void;
  onSaveNote: (data: any) => void;
  onUpdateDeliveryNote: (data: any) => void;
  setOpenConfirmSameDayDeliveryModal: (data: boolean) => void;
}

type Props = StateProps & DispatchProps & OwnProps;

export class CheckoutStep2Page extends React.PureComponent<Props> {
  public render() {
    return (
      <Fragment>
        <Helmet>
          <title>{translations(messages.headerStep2)}</title>
          <meta name="description" content={`${translations(messages.headerStep2)}`} />
        </Helmet>
        <CheckoutStep2
          loading={this.props.loading}
          checkout={this.props.checkout}
          onChangeDeliveryDate={this.props.onUpdateDeliveryDetails}
          onChangeDeliveryTime={this.props.onUpdateDeliveryDetails}
          onGoPreviousStep={this.props.onGoPreviousStep}
          onCreateOrderDraft={this.props.onCreateOrderDraft}
          onSaveNote={this.props.onSaveNote}
          onUpdateDeliveryNote={this.props.onUpdateDeliveryNote}
          cart={this.props.cart}
          orderDisabled={this.props.orderDisabled.orderDisabled}
          openConfirmSameDayDeliveryModal={this.props.openConfirmSameDayDeliveryModal}
          setOpenConfirmSameDayDeliveryModal={this.props.setOpenConfirmSameDayDeliveryModal}
        />
      </Fragment>
    );
  }
}

const mapStateToProps = createStructuredSelector<RootState, StateProps>({
  checkoutstep1page: selectCheckoutStep1Page(),
  cart: selectCart(),
  checkout: selectCheckout(),
  loading: selectLoadingCheckout(),
  orderDisabled: selectOrderDisabled(),
  openConfirmSameDayDeliveryModal: selectOpenConfirmSameDayDeliveryModal(),
});

function mapDispatchToProps(dispatch: Dispatch, ownProps: OwnProps): DispatchProps {
  return {
    onCreateOrderDraft: () => dispatch(createOrderDraft()),
    onGoPreviousStep: () => dispatch(push('/checkout/step/1')),
    onUpdateDeliveryDetails: (data: any) => dispatch(updateDeliveryDetails(data)),
    onSaveNote: (data: any) => dispatch(addNoteSubmit(data)),
    onUpdateDeliveryNote: (data: any) => dispatch(updateDeliveryNote(data)),
    setOpenConfirmSameDayDeliveryModal: (data: boolean) => dispatch(setOpenConfirmSameDayDeliveryModal(data)),
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

// <OwnProps> restricts access to the HOC's other props. This component must not do anything with reducer hoc
const withReducer = injectReducer<OwnProps>({
  key: 'checkoutStep2Page',
  reducer: reducer,
});
// <OwnProps> restricts access to the HOC's other props. This component must not do anything with saga hoc
const withSaga = injectSaga<OwnProps>({ key: 'checkoutStep2Page', saga: saga });

export default compose(withReducer, withSaga, withConnect)(CheckoutStep2Page);
